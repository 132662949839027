<template>
    <section class="bg-gray-50">
        <div class="mx-auto max-w-7xl px-4 py-24 sm:py-24 sm:px-6 lg:px-8">
            <div class="sm:flex sm:flex-col sm:items-baseline sm:justify-between">
                <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl capitalize">{{title}}</h2>
                <p v-if="description" class="text-lg leading-8 text-gray-600">{{ description }}</p>
            </div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:grid-rows-1 sm:gap-x-6 lg:gap-8">
                <template v-for="category in categories">
                    <a :href="route('preconfig.category', {slug: category.slug})">
                        <div class="flex flex-col md:gap-y-2">
                            <div class="overflow-hidden sm:aspect-none  sm:h-full relative duration-300 transition">
                                <img :src="category.imagelink" :alt="category.description"
                                     class="object-cover object-center group-hover:opacity-75 sm:inset-0 sm:h-full sm:w-full hover:shadow-lg rounded-lg"/>
                                <div class="flex items-end py-2 md:p-6 sm:absolute sm:inset-0 top-5">
                                    <div class="absolute left-5 top-5">
                                        <h3 class="font-semibold text-primary capitalize">{{ category.name }}</h3>
                                    </div>
                                </div>
                            </div>
                            <p class="text-gray-500 italic">{{ category.description }}</p>
                        </div>
                    </a>
                </template>
            </div>
        </div>
    </section>
</template>

<script setup>
import {usePage} from "@inertiajs/vue3";

const page = usePage();
const categories = page.props.categories;

const props = defineProps({
    title: {
        type: String,
        default: "Onze standing desks"
    },
    description: {
        type: String,
        default: ""
    }
});

</script>

